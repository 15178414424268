import { Injectable } from '@angular/core';
import { httpRequestWithBlock,client } from '@sa-utils/axios';
@Injectable({
  providedIn: 'root'
})
export class TopicTrackerService {

  public timeSecond = 0;
  public timeMili = 0;
  public timeMiliCounter = 0;

  constructor() {
    this.resetWatchTimer();
    this.sendWatchTimer((dataWatch) => {
      this.bulkWatchTime(dataWatch)
    });
  }

  resetWatchTimer(){
    this.timeSecond = 0;
    this.timeMili = 0;
    this.timeMiliCounter = 0;
  }

  resetWatchTimerData(){
    this.resetWatchTimer();
    localStorage.removeItem('watchTrack');
  }
  buildDataSendWatchTracker(){
    let dataWatch: any = localStorage.getItem('watchTrack') || '';
    let dataSend = [];
    if(dataWatch!=null){
      dataWatch = JSON.parse(dataWatch) || [];
      dataWatch.forEach((item,index)=>{
        const time = parseInt(item.time);
        if(time!==0){
          dataSend.push({
            plot_id:item.plot_id,
            watch_uid:item.watch_uid,
            duration:time,
            uid:item.uid
          });
        }
      });
    }
    return dataSend;
  }
  sendWatchTimer(callback = (data)=>{}){
    let dataWatch: any = localStorage.getItem('watchTrack');
    if(dataWatch!=null){
      dataWatch = JSON.parse(dataWatch);
      dataWatch = dataWatch.filter((item) => {
        const time = parseInt(item.duration);
        return time>0;
      })
      this.resetWatchTimerData();
      if(dataWatch.length>0){
        callback(dataWatch);
      }
    }
  }

  sendWatchTimerFallback(callback = (data) => {}){
    let dataWatch: any = localStorage.getItem('watchTrackFallback');
    if(dataWatch!=null){
      const dataSend: any = JSON.parse(dataWatch);
      // console.log('send watch time data fallback',dataSend);
      if(dataSend.length>0){
        localStorage.removeItem('watchTrackFallback');
        callback(dataSend);
      }
    }
  }

  buildWatchTracker(callback = (data) => {}){
    let dataWatch: any = localStorage.getItem('watchTrack');
    if(dataWatch!=null){
      let dataSend = JSON.parse(dataWatch);
      dataSend = dataSend.filter((item) => {
        const time = parseInt(item.duration);
        return time>0;
      })
      // console.log('send watch time data fallback',dataSend);
      if(dataSend.length>0){
        callback(dataSend);
      }
    }
  }

  async bulkWatchTime(dataWatch: any,callback = (data: any) => {}){
    try {
      const result = await client().post('topic/track',dataWatch[0]);
    } catch(e) {
      callback(e);
    }

  }
  calculateWatchTimer(currentTime,watch_uid,topic_id,uid, callback = () => {}){
    let splitTime =  currentTime.toString().split('.');
    let miliReal = parseFloat('0.'+splitTime[1]);
    const timeDiff = (miliReal-this.timeMiliCounter);
    if(this.timeMiliCounter>miliReal){
      this.timeMiliCounter = 0;
      this.timeSecond+=1;
      this.timeMili = this.timeMiliCounter;
      callback();
      this.putDataTrackWatchTime(watch_uid,topic_id,uid,this.timeSecond);
    }
    this.timeMili += (timeDiff>0?timeDiff:0);
    this.timeMiliCounter = miliReal;
  }

  putDataTrackWatchTime(watch_uid,topic_id,uid,second = 0){
    let dataWatch: any = localStorage.getItem('watchTrack');
    if(dataWatch!=null){
      dataWatch = JSON.parse(dataWatch);
      const index = dataWatch.findIndex((item) => {
        return item.topic_id === topic_id && item.uid === uid;
      });
      if(index !== -1){
        dataWatch[index].duration = second;
      }else{
        const data = {
          topic_id : topic_id,
          uid : uid,
          duration:second,
          watch_uid:watch_uid
        }
        dataWatch.push(data);
      }
      localStorage.setItem('watchTrack',JSON.stringify(dataWatch));
    }else{
      const dataInit = [
        {
          topic_id : topic_id,
          uid : uid,
          duration:second,
          watch_uid:watch_uid
        }
      ];
      localStorage.setItem('watchTrack',JSON.stringify(dataInit));
    }
  }

  putDataTrackWatchTimeFallback(newData){
    let dataWatch: any = localStorage.getItem('watchTrackFallback');
    if(dataWatch!=null){
      dataWatch = JSON.parse(dataWatch);
      localStorage.setItem('watchTrackFallback',JSON.stringify([...dataWatch,...newData]));
    }else{
      localStorage.setItem('watchTrackFallback',JSON.stringify(newData));
    }
  }
}
