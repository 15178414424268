import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CourseListItem} from '@sa-types/course';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component({
  selector: 'sa-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {
  @Input() course: CourseListItem;
  @Input() listPendingTransId: number[] = [];
  @Output() clickPayment =  new EventEmitter();
  @Output() clickDetail =  new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  get isMyCourse(){
    return this.course.type === 'my-course';
  }
  get isSellCourse(){
    return this.course.type === 'sell-course';
  }
  get isExpiredCourse(){
    return this.course.type === 'expired-course';
  }
  onClickPayment(courseId: number){
    this.clickPayment.emit(courseId);
  }
  onClickDetail(courseId: number){
    this.clickDetail.emit({
      courseId,
      isSelfPace: this.isSelfPace
    });
  }
  get isStarted(){
    const now = dayjs().utc().format();
    return now >= dayjs(this.course.start_date).utc().format();
  }
  get isSelfPace(){
    return this.course.program_type === 2;
  }
  getProgressValue(){
    if (!this.isSelfPace){
      return this.course.start_date ? this.course.progress ? this.course.progress : 0 : 0;
    }else if (this.isSelfPace){
      return this.course.progress;
    }
    return 0;
  }

}
