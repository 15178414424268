import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayOption'
})
export class ArrayOptionPipe implements PipeTransform {

  transform(arrayOption: any[]): {id: any, name: any}[] {
    return arrayOption.map(o => ({
      id: o, name: o
    }));
  }

}
