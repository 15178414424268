import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondToMinute'
})
export class SecondToMinutePipe implements PipeTransform {

  transform(input: string): string {
    let sec_num = parseInt(input, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);
    let hours_text = hours.toString();
    let minutes_text = minutes.toString();
    let seconds_text = seconds.toString();
    if (hours < 10) { hours_text = '0' + hours }
    if (minutes < 10) { minutes_text = '0' + minutes }
    if (seconds < 10) { seconds_text = '0' + seconds }
    if (hours > 0) {
      return hours_text + ':' + minutes_text + ':' + seconds_text;
    } else {
      return minutes_text + ':' + seconds_text;
    }
  }

}
