import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { UserModel } from '../../../_models/user.model';
import { AuthModel } from '../../../_models/auth.model';
import { environment } from '@sa-env/environment';
import {clientPublic, clientWithParam} from '@sa-utils/axios';
import {AxiosError, AxiosResponse} from 'axios';
import {FbAuth} from '@sa-types/fbAuth';

const API_USERS_URL = `${environment.apiUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  loginWithToken(token: string): Observable<any> {
    const notFoundError = '';
    if (!token) {
      return of(notFoundError);
    }

    return from(
      clientWithParam({
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).get('account/me')
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data <= 0) {
          return token;
        } else {
          return '';
        }
      })
    );
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    const notFoundError = new Error('Not Found');
    if (!email || !password) {
      return of(notFoundError);
    }

    return from(
      clientPublic().post('auth/login-email', {email, password}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const auth = new AuthModel();
          auth.accessToken = user.token;
          auth.refreshToken = user.refreshToken;
          auth.expiresIn = user.expires_at;
          return auth;
        } else {
          return notFoundError;
        }
      })
    );
  }

  authRoleLogin(token: string): Observable<any> {
    const notFoundError = new Error('Not Found');
    if (!token) {
      return of(notFoundError);
    }

    return from(
      clientPublic().post('auth/auth-role', {token}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const auth = new AuthModel();
          auth.accessToken = user.token;
          auth.refreshToken = user.refreshToken;
          auth.expiresIn = user.expires_at;
          return auth;
        } else {
          return notFoundError;
        }
      })
    );
  }

  authRoleLoginCMS(token: string): Observable<any> {
    const notFoundError = new Error('Not Found');
    if (!token) {
      return of(notFoundError);
    }

    return from(
      clientPublic().post('auth/auth-cms', {token}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const _t = result.data?.data?._t;
          const auth = new AuthModel();
          auth.accessToken = _t.token;
          auth.refreshToken = _t.refreshToken;
          auth.expiresIn = _t.expires_at;
          auth.cid = user.cid;
          return auth;
        } else {
          return notFoundError;
        }
      })
    );
  }

  loginAuto(token: string): Observable<any> {
    const notFoundError = new Error('Not Found');
    if (!token) {
      return of(notFoundError);
    }

    return from(
      clientPublic().get(`auth/single-auto/${token}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const auth = new AuthModel();
          auth.accessToken = user.token;
          auth.refreshToken = user.refreshToken;
          auth.expiresIn = user.expires_at;
          return auth;
        } else {
          return notFoundError;
        }
      })
    );
  }

  loginSocMed(uid: string, type: 'google' | 'facebook'): Observable<any> {
    console.log('logins ', uid);
    const notFoundError = new Error('Not Found');
    if (!uid) {
      console.log('logins ewe', uid);
      return of(notFoundError);
    }
    const data = {};
    data[`${type}_id`] = uid;
    return from(
      clientPublic().post('auth/login-social', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const auth = new AuthModel();
          auth.accessToken = user.token;
          auth.refreshToken = user.refreshToken;
          auth.expiresIn = user.expires_at;
          return auth;
        } else {
          return notFoundError;
        }
      })
    );
  }

  checkEmail(userMdl: UserModel): Observable<any> {
    const notFoundError = new Error('Email already taken');
    const data = {
      email: userMdl.email
    };
    return from(
      clientPublic().post('signup/check-email', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        // return result.data?.data?.valid;
        if (result.data?.data?.valid){
          return result.data?.data?.valid;
        }else{
          throwError('Email already taken');
          return notFoundError;
        }
      })
    );
  }

  createUser(userMdl: UserModel): Observable<any> {
    const notFoundError = new Error('Registration Failed');
    const data = {
      email: userMdl.email,
      password: userMdl.password,
      recaptcha: userMdl.recaptcha,
    };
    return from(
      clientPublic().post('signup/email', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const auth = new AuthModel();
          auth.accessToken = user.token;
          auth.refreshToken = user.refreshToken;
          auth.expiresIn = user.expires_at;
          return auth;
        } else {
          return notFoundError;
        }
      }),
      catchError(() => {
        return throwError('Registration Failed');
      })
    );
  }

  createUserSocMed(userMdl: FbAuth): Observable<any> {
    const notFoundError = new Error('Registration Failed');
    return from(
      clientPublic().post('signup/social', userMdl, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result.data?.data) {
          const user = result.data?.data;
          const auth = new AuthModel();
          auth.accessToken = user.token;
          auth.refreshToken = user.refreshToken;
          auth.expiresIn = user.expires_at;
          return auth;
        } else {
          return notFoundError;
        }
      }),
      catchError(() => {
        return throwError('Registration Failed');
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return from(
      clientPublic().post('auth/forgot-password', { email }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        if (result?.data?.message){
          return true;
        }else{
          return new Error(result?.data?.message);
        }
      }),
      catchError((err: any) => {
        console.log('forgot error', err);
        return throwError(new Error('Something Wrong. Please try again'));
      })
    );
  }

  resetPassword(data: any): Observable<any> {
    return from(
      clientPublic().post('auth/set-password', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).pipe(
      map((result: AxiosResponse<any>) => {
        return true;
      }),
      catchError((err: AxiosError) => {
        return throwError(new Error(err.response.data?.message));
      })
    );
  }

  getUserByToken(token: string): Observable<UserModel> {
    return of(new UserModel());
  }

  getAllUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(API_USERS_URL);
  }
}
