import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PlatformService } from '@sa-services/platform.service';
import { TopicTrackerService } from '@sa-services/topic-tracker.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {nanoid} from 'nanoid';

@Component({
  selector: 'sa-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit, OnDestroy, AfterContentInit {
  @BlockUI()
  blockUI!: NgBlockUI;
  @Input() toggle = false;
  public YT: any;
  @Input() video!: string;
  public player: any;
  @Input() width: string = '0px';
  @Input() height: string = '0px';
  @Input() topicId: number;
  @Output() onPlayerReady = new EventEmitter();
  @Output() submitProgress = new EventEmitter();
  @Output()
  videoEnded = new EventEmitter<void>();
  watchUID: string = '';
  counterSend = 0;
  duration = 0;
  intervalYT: any = null;
  goals: number = 0;
  saved = false;
  constructor(private ps: PlatformService, private tt: TopicTrackerService) { }

  ngOnInit(): void {
    this.watchUID = nanoid(25);
    this.blockUI.start('Loading Video');
    this.init();
    (window as any).onYouTubeIframeAPIReady = (e: any) => {
      this.onPlayerReady.emit(true);
      setTimeout(()=>{
        this.createPlayer();
      },500)
    };
  }

  ngAfterContentInit(): void {
  }

  init(): void{
    if (this.ps.isBrowser) {
      if((window as any).YT){
        setTimeout(()=>{
          console.log('play dong');
          this.createPlayer();
        },500)
      }
      if(!document.getElementById('iframe-api-script')){
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.id = 'iframe-api-script';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
      }
    }
  }

  createPlayer(){
    this.YT = (window as any).YT;
    this.player = new (window as any).YT.Player('yt-player', {
      videoId: this.getYTID(this.video),
      width: '100%',
      height: '100%',
      playerVars: {
        'autoplay': 1,
        'controls': 1,
        'modestbranding':1,
        'playsinline': 1,
        'showinfo': 0,
        'rel': 0,
        'theme': "light",
      },
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
        onError: this.onPlayerError.bind(this),
        onReady: (event: any) => {
          this.blockUI.stop();
          event.target.playVideo();
        }
      }
    });
  }

  onPlayerStateChange(event: any): void {
    switch (event.data) {
      case (window as any).YT.PlayerState.PLAYING:
        if(this.goals===0){
          this.duration = this.player.getDuration();
          this.goals = this.duration*0.8;
        }
        const _this = this;
        if(this.intervalYT === null){
          this.intervalYT = setInterval(function(){
            if(event.data == _this.YT.PlayerState.PLAYING){
              _this.tt.calculateWatchTimer(_this.player.getCurrentTime(),_this.watchUID,_this.topicId,0,() => {
                _this.sendTracker()
              })
              if(_this.goals>0 && _this.goals<=_this.player.getCurrentTime() && !_this.saved){
                _this.saved = true;
                _this.submitProgress.emit(true)
              }
            }
          },500);
        }
        break;
      case (window as any).YT.PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() !== 0) {
        //   console.log('paused' + ' @ ' + this.cleanTime());
        }
        break;
      case (window as any).YT.PlayerState.ENDED:
        // console.log('ended ');
        this.watchUID = nanoid(25);
        clearInterval(this.intervalYT);
        this.intervalYT = null;
        this.tt.sendWatchTimer((dataWatch) => {
          this.tt.bulkWatchTime(dataWatch)
        });
        this.counterSend = 0;
        break;
    }
  }

  cleanTime(): number {
    return Math.round(this.player.getCurrentTime());
  }

  onPlayerError(event: any): void {
    switch (event.data) {
      case 2:
        // console.log('' + this.video);
        break;
      case 100:
        break;
      case 101:
        break;
      case 150:
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.ps.isBrowser) {
    }
  }

  getYTID(urlInput: string): string{
    let IDSplit: string[];
    let ID = '';
    let URLSplit: string[];
    URLSplit = urlInput.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (URLSplit[2] !== undefined) {
      IDSplit = URLSplit[2].split(/[^0-9a-z_\-]/i);
      ID = IDSplit[0];
    }
    else {
      ID = urlInput;
    }
    return ID;
  }

  async sendTrackerData(){
    this.tt.buildWatchTracker(async (dataWatch) => {
      await this.tt.bulkWatchTime(dataWatch)
    });
  }
  sendTracker(){
    this.counterSend++;
    if(this.counterSend>10){
      this.counterSend = 0;
      this.sendTrackerData();
    }
  }

}
