import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  id: number;
  username: string;
  password: string;
  fullname: string;
  email: string;
  recaptcha: string;
  show_tooltip: boolean;
  is_complete_address: boolean;
  is_complete_profile: boolean;
  email_verified_at: Date | null;
  pic: string;
  roles: number[];
  role: string[];
  occupation: string;
  companyName: string;
  dob?: string;
  gender?: string;
  study_level?: string;
  job?: string;
  province?: string;
  province_id?: number;
  city?: string;
  city_id?: number;
  subdistrict?: string;
  subdistrict_id?: number;
  village?: string;
  village_id?: number;
  postal_code?: number;
  source?: string;
  avatar?: string | ArrayBuffer;
  phone: string;
  address?: AddressModel;
  socialNetworks?: SocialNetworksModel;
  // personal information
  firstname: string;
  lastname: string;
  website: string;
  // account information
  language: string;
  timeZone: string;
  communication: {
    email: boolean,
    sms: boolean,
    phone: boolean
  };
  // email settings
  emailSettings: {
    emailNotification: boolean,
    sendCopyToPersonalEmail: boolean,
    activityRelatesEmail: {
      youHaveNewNotifications: boolean,
      youAreSentADirectMessage: boolean,
      someoneAddsYouAsAsAConnection: boolean,
      uponNewOrder: boolean,
      newMembershipApproval: boolean,
      memberRegistration: boolean
    },
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean,
      tipsOnGettingMoreOutOfKeen: boolean,
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean,
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean,
      tipsOnMetronicBusinessProducts: boolean
    }
  };

  setUser(user: any) {
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.fullname = user.fullname || '';
    this.recaptcha = user.recaptcha || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.avatar = user.avatar || './assets/media/users/default.jpg';
    this.roles = user.roles || [];
    this.occupation = user.occupation || '';
    this.companyName = user.companyName || '';
    this.phone = user.phone || '';
    this.address = user.address;
    this.socialNetworks = user.socialNetworks;
    this.show_tooltip = user.show_tooltip || true;
    this.is_complete_address = user.is_complete_address || false;
    this.is_complete_profile = user.is_complete_profile || false;
    this.source = user.source || '';
    this.email_verified_at = user.email_verified_at || null;
  }
}
