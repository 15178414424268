import axios, { Method, AxiosResponse, AxiosError } from 'axios';
import { environment } from '@sa-env/environment';
import { NgBlockUI } from 'ng-block-ui';

export const SESSION_KEY = `${environment.appVersion}-${environment.USERDATA_KEY}`
const TIMEOUT = 10000 * 6; // 1 minute

export function client() {
  return axios.create({
    baseURL: environment.apiUrl,
    responseType: 'json',
    timeout: TIMEOUT,
    ...localStorage && localStorage.getItem(SESSION_KEY) ? {
      headers: {
        'Authorization': 'Bearer '+localStorage.getItem(SESSION_KEY)
      }
    }: {}
  });
}

export function clientUpload() {
  return axios.create({
    baseURL: environment.apiUrl,
    responseType: 'json',
    timeout: TIMEOUT,
    ...localStorage && localStorage.getItem(SESSION_KEY) ? {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer '+localStorage.getItem(SESSION_KEY)
      }
    }: {}
  });
}

export function clientPublic(adapter: any = null) {
  return axios.create({
    baseURL: environment.apiUrl,
    responseType: 'json',
    timeout: TIMEOUT,
    ...!!adapter ? {
      adapter
    } : {}
  });
}

export function clientWithParam(params: any) {
  return axios.create({
    baseURL: environment.apiUrl,
    responseType: 'json',
    timeout: TIMEOUT,
    ...params
  });
}

export async function httpRequestWithBlock(uri: string, method: Method, blockUI: NgBlockUI, params?: any, data?: any): Promise<AxiosResponse> {
  blockUI.start();
  try {
    const result = await client().request({
      method,
      params,
      ...data ? { data } : {},
      url: uri
    });
    blockUI.stop();
    return result;
  } catch (e: AxiosError | any) {
    blockUI.stop();
    // console.log(e, e?.response?.data?.message);
    if (e?.response?.data?.message){
      throw new Error(e?.response?.data?.message);
    }else{
      return {
        data: {},
        status: 500,
        statusText: '',
        headers: undefined,
        config: {}
      };
    }
  }
}

export async function httpRequestUpload(uri: string, method: Method, blockUI: NgBlockUI, params?: any, data?: any): Promise<AxiosResponse> {
  blockUI.start('please wait...');
  try {
    const result = await clientUpload().request({
      method,
      params,
      ...data ? { data } : {},
      url: uri,
      onUploadProgress: (evt) => {
        const percentCompleted = Math.round((100 * evt.loaded) / evt.total);
        blockUI.start(`upload progress: ${percentCompleted}%`);
        blockUI.stop();
        if(percentCompleted >= 100){
          blockUI.stop();
          blockUI.start('upload progress: 100...please wait...');
        }
      }
    });
    blockUI.stop();
    return result;
  } catch (e: AxiosError | any) {
    blockUI.stop();
    // console.log(e, e?.response?.data?.message);
    if (e?.response?.data?.message){
      throw new Error(e?.response?.data?.message);
    }else{
      throw new Error(e);
      return {
        data: {},
        status: 500,
        statusText: '',
        headers: undefined,
        config: {}
      };
    }
  }
}
