import { Injectable } from '@angular/core';
import { AuthService } from '@sa-app/modules/auth';
import { Notification } from '@sa-types/common';
import { client, httpRequestWithBlock } from '@sa-utils/axios';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, Observable } from 'rxjs';

const PULL_INTERVAL = 30000;

type NotificationMeta = {
  morePage: 'Y' | 'N',
  nextPage: number,
  recordsTotal: number
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  @BlockUI()
  blockUI: NgBlockUI;

  #notifications: Notification[] = [];
  #notificationNumber: number = 0;

  public notifications$: Observable<Notification[]>;
  private notificationsSubject: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);

  public notificationsNumber$: Observable<number>;
  private notificationsNumberSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private auth: AuthService
  ) {
    this.notifications$ = this.notificationsSubject.asObservable();
    this.notificationsNumber$ = this.notificationsNumberSubject.asObservable();
  }

  initNotifications() {
    this.getNotificationNumber();
    setInterval(async () => {
      this.getNotificationNumber();
    }, PULL_INTERVAL);
  }

  async getNotificationNumber() {
    const user = this.auth.currentUserValue;
    if (user) {
      const response = await client().get(`notification/total`);
      this.#notificationNumber = response?.data?.data || 0;
      this.notificationsNumberSubject.next(this.#notificationNumber);
    }
  }

  async getNotifications(page = 1) {
    const response = await httpRequestWithBlock(`notification?page=${page}`, 'GET', this.blockUI);
    if (response.data) {
      this.#notificationNumber = response?.data?.meta?.totalUnread || 0;
      this.notificationsNumberSubject.next(this.#notificationNumber);
      return response.data;
    } else {
      return;
    }
  }
}
