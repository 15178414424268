import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/_services/auth.guard';
import { SsoComponent } from './modules/auth/sso/sso.component';
import {SsoCmsComponent} from '@sa-app/modules/auth/sso-cms/sso-cms.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'sso/:token',
    component: SsoComponent
  },
  {
    path: 'sso-cms/:token',
    component: SsoCmsComponent
  },
  {
    path: 'cms',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./semesta-modules/cms/cms.module').then((m) => m.CmsModule),
  },
  {
    path: 'self-paced',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./semesta-modules/self-paced/self-paced.module').then((m) => m.SelfPacedModule),
  },
  {
    path: 'single-activity',
    loadChildren: () =>
      import('./semesta-white-label/semesta-white-label.module').then((m) => m.SemestaWhiteLabelModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
