import { Component } from '@angular/core';

@Component({
  selector: 'sa-block-temp',
  styles: [`
    .blockui {
      background: #ffffff;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      border-radius: 0;
    }
    .blockui.blockui-noshadow {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .blockui > span {
      color: #3F4254;
      padding: 0.75rem 1.2rem;
      font-size: 1rem;
      font-weight: 400;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .blockui > span > .loader,
    .blockui > span > .spinner {
      margin-right: 1.25rem;
    }
  `],
  template: `
    <div class="blockui ">
      <span>{{message || 'mohon tunggu...'}}</span>
          <span>
        <span class="spinner  spinner-success "></span>
      </span>
    </div>
  `
})
export class BlockTemplateComponent {
  message: any;
}
