import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[saDropzone]'
})
export class DropzoneDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @Input() disable = false;
  constructor() { }

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('dragover');
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('dragleave');
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log('drop');
    if (!this.disable){
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        this.fileDropped.emit(files[0]);
      }
    }
  }

}
