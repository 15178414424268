<h2 class="title-available-course d-flex align-items-center text-dark font-weight-bold mr-3">{{listTitle}}</h2>
<div class="course-container flex-column flex-md-row">
  <ng-container *ngFor="let data of listData; let i = index">
    <sa-course-card
      [listPendingTransId]="listPendingTransId"
      [course]="data"
      (clickPayment)="onClickPayment($event)"
      (clickDetail)="onClickDetail($event)"
    ></sa-course-card>
  </ng-container>
</div>
