import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { AppService } from '@sa-services/app.service';
import { LinkEnabledDirective } from './directives/link-enabled.directive';
import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';
import { IconComponent } from './components/icon/icon.component';
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component';
import { TadotvPlayerComponent } from './components/tadotv-player/tadotv-player.component';
import { SafePipe } from './pipes/safe.pipe';
import { ArrayOptionPipe } from './pipes/array-option.pipe';
import { CourseCardListComponent } from './components/course-card-list/course-card-list.component';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { TzPipe } from '@sa-shared/pipes/tz.pipe';
import { CountDownPipe } from './pipes/count-down.pipe';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { FileProxyPipe } from './pipes/file-proxy.pipe';
import { IncludesPipe } from './pipes/includes.pipe';
import { SecondToMinutePipe } from './pipes/second-to-minute.pipe';
import { DebounceInputDirective } from './directives/debounce-input.directive';
import { DropzoneDirective } from './directives/dropzone.directive';
import { StatusTagComponent } from './components/status-tag/status-tag.component';
import { SvgDirective } from './directives/inline-svg/inline-svg.directive';

const components = [
  LoadingSpinnerComponent,
  VjsPlayerComponent,
  IconComponent,
  YoutubePlayerComponent,
  CourseCardListComponent,
  CourseCardComponent,
  StatusTagComponent,
  TadotvPlayerComponent
];

const pipes = [
  SafePipe,
  ArrayOptionPipe,
  CountDownPipe,
  MomentFormatPipe,
  FileProxyPipe,
  SecondToMinutePipe,
  IncludesPipe,
  TzPipe
];

const directives = [
  LinkEnabledDirective,
  DebounceInputDirective,
  DropzoneDirective,
  SvgDirective,
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  exports: [
    ...components,
    ...directives,
    ...pipes,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    AppService
  ]
})
export class SharedModule { }
