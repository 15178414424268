import { Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import memo from 'memo-decorator';

@Component({
  selector: 'sa-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit, OnChanges {
  @Input()
  value: string = 'boards';
  @Input()
  size: number = 16;
  @Input()
  className: string = '';
  @Input()
  asImage: boolean = false;
  @Input()
  set pointer(value: boolean | string) {
    this._pointer = !!value;
  }
  _pointer: boolean = false;

  iconPath: string = 'assets/images/icons/';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public elementRef: ElementRef,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    // if (!this.value) {
    //   throw new Error("Attribute 'value' is required");
    // }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] || changes['className']) {
      if (this.asImage) {
        const img: HTMLImageElement = this.document.createElement('img');
        img.src = `${this.iconPath}${this.value || 'boards'}.svg`;
        this.setImgSvgElement(img);
      } else {
        const rawSvg = await this.loadSVG(this.value || 'boards');
        const svg = this.svgFromString(rawSvg);
        this.setSvgElement(svg);
      }
    }
  }

  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  async loadSVG(fileName: string): Promise<string> {
    const url = `${this.iconPath}${fileName}.svg`;
    return await this.httpClient.get(url, { responseType: 'text' }).toPromise();
  }

  setSvgElement(svg: SVGElement) {
    this.clearSvgElement();
    this.elementRef.nativeElement.setAttribute('style', `width: ${this.size}px; height: ${this.size}px`);
    this.elementRef.nativeElement.appendChild(svg);
  }

  setImgSvgElement(svg: HTMLImageElement) {
    this.clearSvgElement();
    this.elementRef.nativeElement.appendChild(svg);
  }

  clearSvgElement() {
    const currentSvg = this.elementRef.nativeElement.childNodes[0];
    if (currentSvg) {
      this.elementRef.nativeElement.removeChild(currentSvg);
    }
  }

  svgFromString(str: string): SVGElement {
    const div: HTMLDivElement = this.document.createElement('div');
    div.innerHTML = str;
    const svg = div.querySelector('svg') as unknown as SVGElement;

    if (!svg) {
      throw Error(`<svg> tag not found for ${this.value}`);
    }

    if (this._pointer) {
      svg.classList.add('pointer');
    }

    if (this.className) {
      svg.classList.add(this.className);
    }
    svg.style.display = 'block';
    svg.setAttribute('height', this.size.toString());
    svg.setAttribute('width', this.size.toString());

    return svg;
  }
}
