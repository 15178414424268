import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {

  transform(source: string[] | string, search: string): boolean {
    return source.includes(search);
  }

}
