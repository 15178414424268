import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import {CourseListItem} from '@sa-types/course';

@Component({
  selector: 'sa-course-card-list',
  templateUrl: './course-card-list.component.html',
  styleUrls: ['./course-card-list.component.scss']
})
export class CourseCardListComponent implements OnInit {
  @Input() listTitle: string = '';
  @Input() listPendingTransId: number[] = [];
  @Input() listData: CourseListItem[];
  @Output() clickPayment =  new EventEmitter<number>();
  @Output() clickDetail =  new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onClickPayment(courseId: number){
    this.clickPayment.emit(courseId);
  }

  onClickDetail(ev: any){
    this.clickDetail.emit({
      courseId: ev.courseId,
      isSelfPace: ev.isSelfPace
    });
  }

}
