import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, from, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {UserModel} from '@sa-app/modules/auth';
import {AuthHTTPService} from './auth-http';
import {environment} from '@sa-env/environment';
import {Router} from '@angular/router';
import {client} from '@sa-utils/axios';
// import firebase from 'firebase/app';
import { FacebookAuthProvider, GoogleAuthProvider } from '@angular/fire/auth';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AuthModel} from '@sa-app/modules/auth/_models/auth.model';
import {FbAuth} from '@sa-types/fbAuth';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private courseIdLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}-cid`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel> = null;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    public afAuth: AngularFireAuth,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(new UserModel());
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  loginWithToken(token: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginWithToken(token).pipe(
      map((res: string) => {
        const result = this.setAuthFromLocalStorage(token);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(new UserModel());
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth.accessToken);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginWithRoleToken(token: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.authRoleLogin(token).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth.accessToken);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginWithRoleTokenCMS(token: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.authRoleLoginCMS(token).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth.accessToken);
        this.setCourseFromLocalStorage(auth.cid.toString());
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginSocMed(uid: string, type: 'google' | 'facebook'): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginSocMed(uid, type).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth.accessToken);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loginAuto(token): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginAuto(token).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth.accessToken);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  FacebookAuth(callback = (fbAuth: FbAuth | undefined) => {}) {
    const provider = new FacebookAuthProvider();
    provider.addScope('email,public_profile');
    return this.AuthLogin(provider, callback);
  }

  GoogleAuth(callback = (fbAuth: FbAuth | undefined) => {}) {
    const provider = new GoogleAuthProvider();
    provider.addScope('email,profile');
    return this.AuthLogin(provider, callback);
  }

  AuthLogin(provider: any, callback = (fbAuth: FbAuth | undefined) => {}) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result: any) => {
        const tok = await result.user?.getIdTokenResult();
        const token = tok?.token || '';
        this.afAuth.authState.subscribe(user => {
          if (user) {
            // console.log('user', user);
            // console.log('res', result);
          }
        });
        callback({
          social_id: result.additionalUserInfo?.profile?.id || '',
          token,
          email: result.additionalUserInfo?.profile?.email
        });
      }).catch((error) => {
        // console.error(error);
        callback(undefined);
      });
  }

  logout(noRedirect = false) {
    localStorage.removeItem(this.authLocalStorageToken);
    this.currentUserValue = new UserModel();
    if(!noRedirect){
      this.router.navigate(['/auth/login'], {
        queryParams: {},
      }).then(() => {
        window.location.reload();
      });
    }
  }

  getUserByToken(): Observable<UserModel> {
    const token = this.getAuthFromLocalStorage();
    if (!token) {
      return of(new UserModel());
    }
    this.isLoadingSubject.next(true);
    return from(

      client().get('account/me')
    ).pipe(
      map((result: any) => {
        if (result.data?.data) {
          const user = result.data?.data;
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
          return user;
        } else {
          // this.logout();
          return undefined;
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.checkEmail(user).pipe(
      map((valid: any) => {
        return valid;
      }),
      switchMap((valid: any) => {
        if (valid === true){
          return this.authHttpService.createUser(user);
        }else{
          return throwError('Email already taken');
        }
      }),
      map((auth: AuthModel) => {
        if (auth){
          return this.setAuthFromLocalStorage(auth.accessToken);
        }
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err: Error) => {
        return of(err?.message ?? err);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  registrationSocMed(fbAuth: FbAuth): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUserSocMed(fbAuth).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth.accessToken);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(
        catchError((err: any) => {
          return of(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  resetPassword(data: any): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .resetPassword(data)
      .pipe(
        catchError((err: any) => {
          return of(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  // private methods
  private setAuthFromLocalStorage(token: string): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (token) {
      localStorage.setItem(this.authLocalStorageToken, token);
      return true;
    }
    return false;
  }
  private setCourseFromLocalStorage(id: string): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (id) {
      localStorage.setItem(this.courseIdLocalStorageToken, id);
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): string | undefined {
    try {
      const authData = localStorage.getItem(this.authLocalStorageToken) || '';
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public getCourseFromLocalStorage(): number | undefined {
    try {
      const course = localStorage.getItem(this.courseIdLocalStorageToken) || undefined;
      return Number(course);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
