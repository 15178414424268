import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'countDown',
  pure: false
})
export class CountDownPipe implements PipeTransform {

  transform(created: string, expire: number, type = 'minute'): string {
    const now = moment().valueOf();
    const deadLine = moment(new Date(created)).valueOf() + expire;
    let diff = deadLine - now;
    return this.msToHMS(diff, type === 'minute');
  }

  msToHMS(ms: number, isMinute: boolean) {
    // 1- Convert to seconds:
    let seconds: any = Math.floor(ms / 1000).toString().padStart(2, '0');
    // 2- Extract hours:
    let hours = Math.floor(+seconds / 3600 ).toString().padStart(2, '0'); // 3,600 seconds in 1 hour
    seconds = +seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    let minutes = Math.floor( +seconds / 60 ).toString().padStart(2, '0'); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return hours+":"+minutes + (isMinute ? "" : ":"+seconds);
  }
}
