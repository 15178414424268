import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, UserModel } from '..';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'sa-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit, OnDestroy {

  private unsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const token = this.route.snapshot.params['token'];
    if (token) {
      const loginSubscr = this.authService
        .loginWithRoleToken(token)
        .pipe(first())
        .subscribe((user: UserModel) => {
          if (user) {
            // console.log(user, '????');
            this.router.navigate(['/']);
          } else {
            this.showError('Incorrect token.');
          }
        });
      this.unsubscribe.push(loginSubscr);
    } else {
      this.router.navigate['/'];
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  showError(message: string){
    Swal.fire({
      title: '',
      text: message,
      icon: 'error',
      buttonsStyling: false,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
      customClass: {
        cancelButton: 'btn btn-default'
      }
    });
  }
}
