import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[saLinkEnabled]'
})
export class LinkEnabledDirective {

  @Input()
  saLinkEnabled: string;

  constructor(private el: ElementRef) { }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (!this.saLinkEnabled) {
      // console.log('DISABLED');
      event.preventDefault();
      return;
    }
    // console.log('ENABLED');
  }
}
