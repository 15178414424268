import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@sa-env/environment';

@Pipe({
  name: 'fileProxy'
})
export class FileProxyPipe implements PipeTransform {

  transform(banner: string, type: string = 'img'): string {
    return environment.v1Url + 'file/' + type + '-proxy/' + banner.replace('/', '-');
  }

}
