import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {nanoid} from 'nanoid';
import { TopicTrackerService } from '@sa-services/topic-tracker.service';
import { PlatformService } from '@sa-services/platform.service';

@Component({
  selector: 'sa-tadotv-player',
  templateUrl: './tadotv-player.component.html',
  styleUrls: ['./tadotv-player.component.scss']
})
export class TadotvPlayerComponent implements OnInit, AfterContentInit {
  @ViewChild('videoFrame') videoFrame: ElementRef | undefined;
  frameElm: HTMLElement = document.getElementById('video-embed') as HTMLElement;
  @Input() topicId: number;
  @Input() url: string;
  @Output() submitProgress = new EventEmitter();

  watchUID: string = '';
  counterSend = 0;
  duration = 0;
  goals: number = 0;
  saved = false;
  constructor(
    private ps: PlatformService,
    private tt: TopicTrackerService,
  ) { }

  ngOnInit(): void {
    this.watchUID = nanoid(25);
    this.url+=this.ps.isMobile?'?sendp=1&forceMobile=1':'?sendp=1';
  }

  ngAfterContentInit(): void {
    if (this.ps.isBrowser){
      this.frameElm = document.getElementById('video-embed') as HTMLElement;
      window.addEventListener('message', this.eventReadyVideo, false);
    }
  }

  eventReadyVideo = ({data}: any) => {
    if(typeof data === 'string'){
      if(data.includes('player:timeupdate')){
        const msgSplit = data.split(':');
        this.tt.calculateWatchTimer(msgSplit[2],this.watchUID,this.topicId,0,() => {
          this.sendTracker()
        })
      }
      if(data.includes('player:ended')){
        this.submitProgress.emit(true);
        const _this = this;
        this.tt.sendWatchTimer(function(dataWatch){
          _this.tt.bulkWatchTime(dataWatch)
        });
        this.counterSend = 0;
      }
    }
  }

  async sendTrackerData(){
    this.tt.buildWatchTracker(async (dataWatch) => {
      await this.tt.bulkWatchTime(dataWatch)
    });
  }
  sendTracker(){
    this.counterSend++;
    if(this.counterSend>10){
      this.counterSend = 0;
      this.sendTrackerData();
    }
  }
}
