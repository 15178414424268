import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  deviceInfo = null;

  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) { }

  get isServer() {
    return !isPlatformBrowser(this.platformId);
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get isMobile() {
    return this.deviceService.isMobile();
  }

  // get isTablet() {
  //   return this.deviceService.isTablet();
  // }
}
