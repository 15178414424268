<div class="card card-custom gutter-b">
  <div class="card-header">
    <img class="banner-img" [src]="course.banner">
  </div>
  <div class="card-body">
    <div class="top-container">
      <h2>{{course.name}}</h2>
      <ng-container *ngIf="isMyCourse">
        <ng-container *ngIf="course.start_date;else !isSelfPace && TBD">
          <div class="time-wrapper">
            <i class="far fa-clock"></i> <span>{{ course.week_time }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="course.show_expired && isStarted;else course.show_expired && expiredContent"
                      [ngTemplateOutlet]="expiredContent"></ng-container>
      </ng-container>
      <ng-container *ngIf="isSellCourse">
        <ng-container *ngIf="course.start_date;else !isSelfPace && TBD">
          <div class="date-wrapper">
            <i class="far fa-calendar"></i>
            <span>
              Start at {{ course.start_date | tz: 'D MMM YYYY'}}
            </span>
          </div>
        </ng-container>
        <div class="time-wrapper" *ngIf="!isSelfPace">
          <i class="far fa-clock"></i> <span>{{ course.week_time }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isExpiredCourse">
        <div class="time-wrapper mb-2" *ngIf="!isSelfPace">
          <i class="far fa-clock"></i>
          <span>{{ course.week_time}}</span>
        </div>
        <div class="time-wrapper">
          <i class="far fa-calendar"></i>
          <span>Access Expired at {{ course.expired_date}}</span>
        </div>
      </ng-container>
    </div>
    <div class="bottom-container">
      <ng-container *ngIf="isSellCourse" [ngTemplateOutlet]="bottomContainerSell"></ng-container>
      <ng-container *ngIf="isMyCourse" [ngTemplateOutlet]="bottomContainer"></ng-container>
      <ng-container *ngIf="isExpiredCourse" [ngTemplateOutlet]="bottomContainerExpired"></ng-container>
    </div>
  </div>
</div>
<ng-template #TBD>
  <div class="date-wrapper">
    <i class="far fa-calendar"></i>
    <span>TBD</span>
  </div>
</ng-template>
<ng-template #bottomContainerSell>
  <div class="button-container flex-column">
    <ng-container *ngIf="!listPendingTransId.includes(course.id);else sellDisabled">
      <a
        (click)="onClickPayment(course.id)"
        class="btn btn-dark font-weight-bold btn-square"
      >{{course.price===0?'Enroll Free':'Enroll Now'}}</a>
    </ng-container>
    <ng-template #sellDisabled>
      <a class="btn btn-default font-weight-bold btn-square" disabled >{{course.price===0?'Enroll Free':'Enroll Now'}}</a>
    </ng-template>
    <a
      [href]="course.landing_url"
      target="_blank"
      class="btn btn-white font-weight-bold btn-square"
    >Info Tentang Kelas</a>
  </div>
</ng-template>
<ng-template #bottomContainer>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      [ngStyle]="{
        width:course.progress+'%'
      }"
      [attr.aria-valuenow]="course.progress"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
  <span class="progress-value">
    {{getProgressValue()}}% Selesai
  </span>
  <div class="button-container">
    <a (click)="onClickDetail(course.id)" class="btn btn-dark font-weight-bold btn-outline-dark btn-square">Ke Kursus</a>
  </div>
</ng-template>
<ng-template #bottomContainerExpired>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      [ngStyle]="{
        width:course.progress+'%'
      }"
      [attr.aria-valuenow]="course.progress"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
  <span class="progress-value">
    {{course.progress}}% Selesai
  </span>
  <div class="button-container">
    <button
      class="btn btn-dark font-weight-bold btn-outline-dark btn-square"
      disabled
    >
      Access Expired
      <i class="fas fa-lock"></i>
    </button>
  </div>
</ng-template>
<ng-template #expiredContent>
  <div class="time-wrapper expired-now">
    <i class="far fa-calendar"></i>
    <span>Access Expired at {{course.expired_date}}</span>
  </div>
</ng-template>
