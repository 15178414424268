import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {

  transform(date: string, ms: number = 0, format = 'dddd, Do MMMM YYYY | HH:mm'): unknown {
    return moment(new Date(date)).utc(false).add(ms, 'ms').format(format);
  }

}
