import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private promise: Promise<boolean>;
  private resolver!: (value: boolean | PromiseLike<boolean>) => void;

  get ready () { return this.promise; }

  constructor() {
    this.promise = new Promise(resolve => {
      this.resolver = resolve;
    });
  }

  markReady () {
    this.resolver(true);
  }


}
