// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v0.1',
  USERDATA_KEY: 'sAf649fc9a5fTkN55x1s',
  apiUrl: 'https://busy-api-dev.semestaakademi.com/api/v3/',
  // apiUrl: 'https://chatbot-api-dev.semestaakademi.com/api/v3/',
  // apiUrl: 'http://localhost:3333/api/v3/',
  // v1Url: 'http://localhost:8080/',
  v1Url: 'https://busykitchen.semestaakademi.com/',
  chatbotPhoneNumber: '+6281119991308',
  isMockEnabled: false,
  firebase: {
    apiKey: 'AIzaSyDVEhG_MI9DfoPlMeoLKMD8HPSBxUyrKkM',
    authDomain: 'semesta-akademi.firebaseapp.com',
    projectId: 'semesta-akademi',
    storageBucket: 'semesta-akademi.appspot.com',
    messagingSenderId: '90221917998',
    appId: '1:90221917998:web:b514004d6d13f5b8172cbf',
    measurementId: 'G-M66H9TYDXR'
  },
  recaptcha: {
    siteKey: '6LdiEzYdAAAAAAOZ3CNCwxGbfPvge6mqm8saPpgn',
    secretKey: '6LdiEzYdAAAAAB9Ot-Jat58qqiaSj6KINuXAfzjO',
  },
  xendit: {
    publicKey: 'xnd_public_development_64wzwYe89ZCGxdZzENvFRW2uKrL8Ebqi7RDMYQ4RzxZf3odFltWX4TX7wCqha2',
    secretKey: 'xnd_development_fpssJwN2xQkiwqoO2BWT7G5OwQ9W4dEcDF82KYmwdAnrzx2FZCwhsXBRxHdLP'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
