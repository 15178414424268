import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import videojs from 'video.js';
import {nanoid} from 'nanoid';
import { TopicTrackerService } from '@sa-services/topic-tracker.service';

@Component({
  selector: 'sa-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };
  @Input() topicId: number;
  @Output() onPlayerReady = new EventEmitter();
  @Output() submitProgress = new EventEmitter();
  @Output()
  videoEnded = new EventEmitter<void>();

  player: videojs.Player;
  watchUID: string = '';
  counterSend = 0;
  duration = 0;
  goals: number = 0;
  saved = false;

  constructor(
    private elementRef: ElementRef,
    private tt: TopicTrackerService,
  ) { }

  ngOnInit() {
    this.watchUID = nanoid(25);
    // instantiate Video.js
    const event = this.videoEnded;
    const _this = this;
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      // console.log('onPlayerReady', this);
      this.on('ended', () => {
        _this.watchUID = nanoid(25);
        _this.tt.sendWatchTimer((dataWatch) => {
          _this.tt.bulkWatchTime(dataWatch)
        });
        _this.counterSend = 0;
      });
      this.on('timeupdate',() => {
        if(_this.goals===0){
          _this.duration = this.duration();
          _this.goals = _this.duration*0.8;
        }
        if(_this.goals>0 && _this.goals<=this.currentTime() && !_this.saved){
          _this.saved = true;
          _this.submitProgress.emit(true)
        }
        _this.tt.calculateWatchTimer(this.currentTime(),_this.watchUID,_this.topicId,0,() => {
          _this.sendTracker()
        })
      })
    });
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  async sendTrackerData(){
    this.tt.buildWatchTracker(async (dataWatch) => {
      await this.tt.bulkWatchTime(dataWatch)
    });
  }
  sendTracker(){
    this.counterSend++;
    if(this.counterSend>10){
      this.counterSend = 0;
      this.sendTrackerData();
    }
  }
}
