import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, UserModel} from '@sa-app/modules/auth';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {first} from 'rxjs/operators';

@Component({
  selector: 'sa-sso-cms',
  templateUrl: './sso-cms.component.html',
  styleUrls: ['./sso-cms.component.scss']
})
export class SsoCmsComponent implements OnInit, OnDestroy {

  private unsubscribe: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const token = this.route.snapshot.params['token'];
    if (token) {
      const loginSubscr = this.authService
        .loginWithRoleTokenCMS(token)
        .pipe(first())
        .subscribe((user: UserModel) => {
          if (user) {
            const courseId = this.authService.getCourseFromLocalStorage();
            // console.log(user, '????');
            this.router.navigate([`/cms/course/${courseId}`]);
          } else {
            this.showError('Incorrect token.');
          }
        });
      this.unsubscribe.push(loginSubscr);
    } else {
      this.router.navigate['/'];
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  showError(message: string){
    Swal.fire({
      title: '',
      text: message,
      icon: 'error',
      buttonsStyling: false,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
      customClass: {
        cancelButton: 'btn btn-default'
      }
    });
  }

}
